<template>
  <div @click="$emit('showHide')" class="week-days">
    <div class="name font-size-big">
      {{
        recurring_type == "monthly"
          ? $t("packages.update.modal.dropdown.day") + day.dayValue
          : $t("packages.update.modal.dropdown.every") +
            $t("packages.update.modal.dropdown.week." + week.dayIndex)
      }}
    </div>
    <div class="icon">
      <div class="icon-holder" :class="hidden"></div>
    </div>
    <div class="dropdown" :class="hidden">
      <div
        @click="saveDay(index, day_select)"
        class="day font-size-md"
        v-for="(day_select, index) in Days"
        :key="index"
      >
        {{
          recurring_type == "monthly"
            ? $t("packages.update.modal.dropdown.day") + day_select
            : $t("packages.update.modal.dropdown.every") +
              " " +
              $t("packages.update.modal.dropdown.week." + index)
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DayDropdown",
  emits: ["showHide"],
  props: {
    hidden: {
      type: String,
    },
    recurring_type: {
      type: String,
    },
    Days: {
      type: Array,
    },
    theDay: {
      type: Number,
      default: null,
    },
  },
  mounted() {
    if (this.theDay != null) {
      this.saveDay(this.theDay - 1, this.theDay);
    }
  },
  methods: {
    ...mapActions(["setDay", "destroyDay"]),

    saveDay(dayIndex, dayValue) {
      if (this.recurring_type == "weekly") {
        this.payload.day = {};
        this.payload.week.dayIndex = dayIndex;
        this.payload.week.dayValue = dayValue;
      } else if (this.recurring_type == "monthly") {
        this.payload.week = {};
        this.payload.day.dayIndex = dayIndex;
        this.payload.day.dayValue = dayValue;
      }
      this.setDay(this.payload);
    },
  },
  computed: {
    ...mapGetters({ day: "getDay", week: "getWeek" }),
  },
  watch: {
    theDay: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.saveDay(this.theDay - 1, this.theDay);
        }
      },
    },
    $route() {
      this.destroyDay();
    },
  },
  data() {
    return {
      payload: {
        week: {},
        day: {},
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.week-days {
  cursor: pointer;
  position: relative;
  display: flex;
  border: 1px solid map-get($colors, "bordering-gray");
  justify-content: space-between;
  padding: 0 map-get($spacings, "small");
  border-radius: 8px;
  align-items: center;
  height: 50px;
  .name {
    display: flex;
  }
  .icon {
    width: 15px;
    height: 10px;
    .icon-holder {
      background-image: url("../../../assets/arrow_down.svg");
      background-repeat: no-repeat;
      background-size: contain;
      transition: transform 300ms;
      width: 100%;
      height: 100%;
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
  .dropdown {
    &.hidden {
      display: none;
    }
    position: absolute;
    width: 100%;
    left: 0;
    top: 55px;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow: auto;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 5px;
      // color: map-get($colors, "bordering-gray");
    }
    &::-webkit-scrollbar-thumb {
      background-color: map-get($colors, "bordering-gray");
    }
    background-color: map-get($colors, "white");
    z-index: 1;
    border: 1px solid map-get($colors, "bordering-gray");
    border-radius: 8px;
    .day {
      display: flex;
      align-items: center;
      border-bottom: 1px solid map-get($colors, "bordering-gray");
      min-height: 40px;
      margin: 0px map-get($spacings, "small");
      color: map-get($colors, "main");
      &:last-child {
        border-bottom: 0px;
      }
    }
  }
}
</style>