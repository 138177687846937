<template>
  <div class="package-template">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PackageTemplate",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.package-template {
  display: flex;
  gap: map-get($spacings, "default");
  width: 100%;
  height: 100%;
}
</style>