<template>
  <div class="badger">
    <span class="font-size-sm" :class="enabled ? 'active' : 'inactive'">{{
      enabled
        ? $t("packages.template.active")
        : $t("packages.template.inactive")
    }}</span>
  </div>
</template>

<script>
export default {
  name: "PackageBadge",
  props: {
    enabled: {
      type: Boolean,
      // to be removed
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.badger {
  .active {
    text-align: center;
    color: map-get($colors, "white");
    background-color: map-get($colors, "twiks");
    padding: 0px 8px;
    border-radius: 6px;
  }
  .inactive {
    text-align: center;
    color: map-get($colors, "white");
    background-color: map-get($colors, "bordering-gray");
    padding: 0px 8px;
    border-radius: 6px;
  }
}
</style>