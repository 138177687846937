<template>
  <div class="invalid-message" v-if="validation_errors[name]">
    {{ validation_errors[name]['message'] }}
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ValidationError",
  props: ["name"],
  computed: {
    ...mapGetters(["validation_errors"]),
  },
};
</script>

<style>
</style>
