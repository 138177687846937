<template>
  <div class="package-color-holder">
    <div
      :style="{ backgroundColor: colors[colorize % colors.length] }"
      class="package-color-name font-size-lg"
    >
      {{ firstChar }}
      <div class="icon-container">
        <div class="icon"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PackageColor",
  props: {
    color: {
      type: String,
    },
  },
  computed: {
    colorize() {
      return this.color.charCodeAt(0);
    },
    firstChar() {
      return this.color.charAt(0);
    },
  },
  data() {
    return {
      colors: [
        "#EC524B",
        "#F5B461",
        "#89C733",
        "#5C969E",
        "#3D7EA6",
        "#794C74",
        "#C56183",
        "#0F3057",
        "#008891",
        "#8BC4D6",
        "#8BC4D6",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.package-color-holder {
  display: flex;
  align-items: center;
  .package-color-name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    @media (max-width: 300px) {
      width: 45px;
      height: 45px;
    }
    border-radius: 8px;
    position: relative;
    color: map-get($colors, "white");
    font-weight: bold;
    .icon-container {
      position: absolute;
      display: flex;
      top: 0;
      right: 0;
      padding: map-get($spacings, "xsmall") map-get($spacings, "xsmall");
      .icon {
        height: 10px;
        width: 10px;
        background-image: url("../../../assets/package/package.svg");
        background-size: fill;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>