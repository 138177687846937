<template>
  <div class="package-icon">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PackageIcon",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.package-icon {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>