<template>
  <div class="form-check" :class="{ 'form-check-inline': inline }">
    <input
      ref="input"
      class="form-check-input"
      :type="type"
      :name="name"
      :id="'identifier-' + unique_id"
      :value="value"
      :checked="checked === true || checked === 'true'"
      @click="radioButtonClicked(value)"
      v-bind="$attrs"
    />
    <label
      v-bind="$attrs"
      class="form-check-label"
      v-bind:for="'identifier-' + unique_id"
    >
      <slot>as</slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "FormCheckInput",
  props: {
    type: {
      type: String,
      default: function () {
        return "checkbox";
      },
    },
    inline: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    checked: {
      type: [String, Boolean],
      default: function () {
        return false;
      },
    },
    value: {
      type: [String, Number],
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      unique_id: Math.floor(Math.random() * (9999 - 1000)) + 1000,
    };
  },
  methods: {
    radioButtonClicked(value) {
      let checked = false;
      if (this.$refs.input.checked === true) {
        checked = true;
      }

      this.$emit("changed", {
        value: value,
        checked: checked,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check {
  &.customise {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  .form-check-input {
    &.customise {
    }
  }
  .form-check-label {
    &.customise {
      margin: 0px;
      margin-top: map-get($spacings, "small");
      width: 100%;
      cursor: pointer;
    }
  }
}
</style>
