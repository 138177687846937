export default {
  data() {
    return {
      isFocused: false,
      id: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, ""),
    };
  },
  mounted() {
    if (this.modelValue.length > 0 && this.placeholder?.length > 0) {
      this.isFocused = true;
    }
  },
  methods: {
    onBlur() {
      if (this.modelValue.length === 0) {
        this.isFocused = false;
      }
    },
    onFocus() {
      this.isFocused = true;
    },
  },
  watch: {
    placeholder() {
      if (this.placeholder !== null && this.placeholder.length > 0) {
        this.isFocused = true;
      }
    },
  },
};
