<template>
  <div class="package-activity">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PackageCardContent",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.package-activity {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>