const WEEK = [1, 2, 3, 4, 5, 6, 7];
const DAY = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
];

function formatDateTime(date, time) {
  let dateTime = date + " " + time + ":00";
  let dateTimeUTC = stringifyDateUTC(dateTime);
  return dateTimeUTC;
}
function stringifyDateUTC(dateTime) {
  dateTime = new Date(dateTime);
  return (
    dateTime.getUTCFullYear() +
    "-" +
    (dateTime.getUTCMonth() + 1 < 10
      ? "0" + (dateTime.getUTCMonth() + 1).toString()
      : dateTime.getUTCMonth() + 1) +
    "-" +
    (dateTime.getUTCDate() < 10
      ? "0" + dateTime.getUTCDate().toString()
      : dateTime.getUTCDate()) +
    " " +
    (dateTime.getUTCHours() < 10
      ? "0" + dateTime.getUTCHours().toString()
      : dateTime.getUTCHours()) +
    ":" +
    (dateTime.getUTCMinutes() < 10
      ? "0" + dateTime.getUTCMinutes().toString()
      : dateTime.getUTCMinutes()) +
    ":" +
    "00"
  );
}
function formatTimeToUtc(time) {
  let date = new Date();
  let array = time.split(":");

  let constructed_date = date.setHours(array[0], array[1]);
  date = new Date(constructed_date);
  let utc_time =
    (date.getUTCHours() < 10
      ? "0" + date.getUTCHours().toString()
      : date.getUTCHours()) +
    ":" +
    (date.getUTCMinutes() < 10
      ? "0" + date.getUTCMinutes().toString()
      : date.getUTCMinutes()) +
    ":00";
  let utc_day = date.getUTCDate();
  let normal_day = date.getDate();

  let returned_object = {
    utc_time: utc_time,
    utc_day: utc_day,
    day: normal_day,
  };
  return returned_object;
}
function formatWeekDay(difference, day) {
  if (difference == 0) {
    return day;
  } else if (difference == 1) {
    if (day == 7) {
      return 1;
    } else {
      return day + 1;
    }
  } else if (difference == -1) {
    if (day == 1) {
      return 7;
    } else {
      return day - 1;
    }
  }
}
function formatDay(difference, day) {
  if (difference == 0) {
    return day;
  } else if (difference == 1) {
    if (day == 30) {
      return 1;
    } else {
      return day + 1;
    }
  } else if (difference == -1) {
    if (day == 1) {
      return 30;
    } else {
      return day - 1;
    }
  }
}
function gmtDateTime(dateTime) {
  return new Date(dateTime);
}
function seperateDateTime(dateTime) {
  let date =
    dateTime.getFullYear() +
    "-" +
    (dateTime.getMonth() + 1 < 10
      ? "0" + (dateTime.getMonth() + 1).toString()
      : dateTime.getMonth() + 1) +
    "-" +
    (dateTime.getDate() < 10
      ? "0" + dateTime.getDate().toString()
      : dateTime.getDate());
  let time =
    (dateTime.getHours() < 10
      ? "0" + dateTime.getHours().toString()
      : dateTime.getHours()) +
    ":" +
    (dateTime.getMinutes() < 10
      ? "0" + dateTime.getMinutes().toString()
      : dateTime.getMinutes()) +
    ":00";
  return { date: date, time: time };
}

function formatTimeToGmt(time) {
  let date = new Date();
  let array = time.split(":");

  let constructed_date = date.setUTCHours(array[0], array[1]);
  date = new Date(constructed_date);
  let gmt_time =
    (date.getHours() < 10
      ? "0" + date.getHours().toString()
      : date.getHours()) +
    ":" +
    (date.getMinutes() < 10
      ? "0" + date.getMinutes().toString()
      : date.getMinutes()) +
    ":00";
  let utc_day = date.getUTCDate();
  let normal_day = date.getDate();

  let returned_object = {
    gmt_time: gmt_time,
    utc_day: utc_day,
    day: normal_day,
  };
  return returned_object;
}
export {
  formatDateTime,
  formatTimeToUtc,
  formatWeekDay,
  gmtDateTime,
  formatDay,
  seperateDateTime,
  formatTimeToGmt,
  DAY,
  WEEK,
};
